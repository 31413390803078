<template>
  <v-container
    id="contents"
    fluid
    tag="section"
  >
  <v-overlay :value="overlay" />
  <v-snackbar
      v-model="snackbar"
      timeout="5000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
  </v-snackbar>
  <v-dialog
    v-model="deleteConfirm"
    persistent
    scrollable
    max-width="400"
  >
    <v-card>
      <v-card-title>
        Conferma cancellazione
      </v-card-title>
      <v-card-text
        v-html="deleteText"
      />
      <v-card-actions>
        <v-spacer />
         <v-btn
          color="green darken-1"
          @click="deleteExec"
        >
          OK
        </v-btn>
        <v-btn
          color="green darken-1"
          @click="deleteConfirm = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="deleteAllConfirm"
    persistent
    scrollable
    max-width="400"
  >
    <v-card>
      <v-card-title>
        Conferma cancellazione di tutte le notifiche
      </v-card-title>
      <v-card-text>
        Confermi la cancellazione di tutte le notifiche?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
         <v-btn
          color="green darken-1"
          @click="deleteAll"
        >
          OK
        </v-btn>
        <v-btn
          color="green darken-1"
          @click="deleteAllConfirm = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-row
      justify="center"
    >
      <v-spacer />
      <v-col :cols="$isMobile()? 10 : 4">
      <v-btn
        dark
        class="mx-2"
        @click="allRead()"
        >
        Marca già lette
        </v-btn>
        <v-btn
        dark
        class="mx-2"
        @click="deleteAllConfirm = true"
        >
        Rimuovi tutte
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        :cols="$isMobile()? 12 : 8"
      >
        <v-card
          elevation="10"
        >
        <v-row
          v-show="dataMissing"
          justify="center"
        >
          <v-col
            cols="2"
          >
            <br>
            <br>
            <br>
            <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
            />
          </v-col>
        </v-row>
        <v-data-table
          elevation="10"
          :headers="headers"
          :items="notifications"
          item-key="name"
          class="elevation-1"
          :search="search"
          :custom-filter="customFilter"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Notifiche per pagina',
            itemsPerPageAllText: 'Tutte le notifiche',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
        <template v-slot:no-data>
          <v-sheet>
            Nessuna notifica presente
          </v-sheet>
        </template>
          <template v-slot:no-results>
            <v-sheet>
              Nessuna notifica
            </v-sheet>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Notifiche per pagina'"
            />
          </template>
          <template v-slot:item.date="{ item }">
            <v-sheet
              v-if="item.unread"
              class="font-weight-medium"
            >
              {{ humanDate(item.date) }}
            </v-sheet>
            <v-sheet
              v-else
              class="font-weight-regular"
            >
              {{ humanDate(item.date) }}
            </v-sheet>
          </template>
          <template v-slot:item.text="{ item }">
            <v-sheet
              v-if="item.unread"
              class="font-weight-medium"
            >
              {{ item.text }}
            </v-sheet>
            <v-sheet
              v-else
              class="font-weight-regular"
            >
              {{ item.text }}
            </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleNotification(item)"
                    >
                      <v-icon
                        v-if="item.unread"
                        small
                      >
                        mdi-email
                      </v-icon>
                      <v-icon
                        v-else
                        small
                      >
                        mdi-email-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.unread">Marca "già letto"</span>
                  <span v-else>Marca "da leggere"</span>
                </v-tooltip>
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteNotification(item)"
                    >
                      <v-icon small>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
         </template>
        </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  const dateFormat = require('dateformat')
  const config = require('@/config')
  const axios = require('axios')
  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }
  export default {
    name: 'Notifications',
    components: { },
    data: () => ({
      dataMissing: true,
      notifications: [],
      search: '',
      dialogTitle: '',
      dialogButtonText: '',
      importFile: '',
      importDialogOpen: false,
      file: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      overlay: false,
      deleteConfirm: false,
      deleteAllConfirm: false,
      deleteText: '',
      deleteId: null,
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Data',
            align: 'start',
            sortable: true,
            value: 'date',
          },
          {
            text: 'Testo',
            align: 'start',
            sortable: false,
            value: 'text',
          },
          {
            text: '',
            align: 'end',
            sortable: false,
            value: 'id',
          },
        ]
      },
    },
    created: function () {
      this.getNotifications()
    },
    methods: {
      humanDate (date) {
        return dateFormat(date, 'd mmm yyyy HH:MM:ss')
      },
      deleteNotification (notification) {
        var component = this
        component.deleteConfirm = true
        component.deleteText = 'Sei sicuro di voler rimuovere la notifica?'
        component.deleteId = notification.id
        component.deleteConfirm = true
      },
      deleteExec () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .delete(config.backendURL + '/notifications/' + component.deleteId, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getNotifications()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Notifica rimossa correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            component.deleteConfirm = false
          })
      },
      deleteAll () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .delete(config.backendURL + '/notifications/all', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getNotifications()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Notifiche rimosse correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
            component.deleteAllConfirm = false
          })
          .catch(function (response) {
            component.deleteAllConfirm = false
          })
      },
      toggleNotification (item) {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios.defaults.headers.put.authorization = 'Bearer ' + currentJWT
        axios
          .put(config.backendURL + '/notifications/toggle/' + item.id, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getNotifications()
              localStorage.setItem(config.authToken, response.data.jwt)
            } else {
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      allRead () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios.defaults.headers.put.authorization = 'Bearer ' + currentJWT
        axios
          .put(config.backendURL + '/notifications/allread', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getNotifications()
              localStorage.setItem(config.authToken, response.data.jwt)
            } else {
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      updateSnack (snack) {
        this.snackbar = snack.snackbar
        this.snackbarText = snack.snackbarText
        this.snackbarColor = snack.snackbarColor
      },
      addUser () {
        this.currentuser = this.emptyUser
        this.currentuser.editing = false
        this.dialogTitle = 'Nuovo socio'
        this.dialogButtonText = 'Aggiungi'
        this.$store.state.userDialogOpen = true
      },
      getNotifications () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        axios
          .get(config.backendURL + '/notifications', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.notifications = response.data.notifications
            localStorage.setItem(config.authToken, response.data.jwt)
            component.dataMissing = false
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      customFilter (value, search, item) {
        try {
          var formatted = dateFormat(value, 'd mmm Y HH:MM:ss')
          return value != null &&
            search != null &&
            typeof value === 'string' &&
            (formatted.toString().indexOf(search) !== -1 || value.toString().indexOf(search) !== -1)
        } catch (error) {
          return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().indexOf(search) !== -1
        }
      },
    },
  }
</script>
<style >
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 20px !important;
    text-transform: uppercase;
    font-weight: 'bold';
  }
</style>
